import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"

const Novosti = () => {
  const data = useStaticQuery(graphql`
    query MyQ {
      allMdx(sort: { fields: frontmatter___datum, order: DESC }) {
        edges {
          node {
            frontmatter {
              datum(formatString: "DD. MM. YYYY.")
              promoslika {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              title
              slug
            }
            id
            excerpt
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Novosti vezane uz projekt dogradnje luke Baška" />
      <main id="main">
        <h1>Novosti</h1>
        <section id="novosti">
          {data.allMdx.edges.map(({ node }) => (
            <article key={node.id} className="objava-preview">
              {/* {node.frontmatter.promoslika} */}
              <Img
                className="objava-preview-slika"
                fluid={node.frontmatter.promoslika.childImageSharp.fluid}
                alt="Promo picture"
              />
              <p className="objava-preview-datum">{node.frontmatter.datum}</p>
              <Link to={`/${node.frontmatter.slug}/`}>
                <h4>{node.frontmatter.title}</h4>
              </Link>
              <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </article>
          ))}
        </section>
      </main>
    </Layout>
  )
}

export default Novosti
